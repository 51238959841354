<template>
  <div class="toast-pwa-refresh">
    <div>
      {{ $t('message.newVersion') }}
    </div>
    <div>
      <button type="button"
        class="btn"
        @click.stop="reload">
        {{ $t('label.refresh') }}
      </button>      
    </div>
  </div>
</template>
<script>
import BaseToastContent from './BaseToastContent.vue'

export default {
  name: "ToastPwaRefresh",
  extends: BaseToastContent,
  methods: {
    reload() {
      location.reload()
    },
  },
}
</script>
<i18n>
{
  "en": {
    "message": {
      "newVersion": "A new version is available."
    },
    "label": {
      "refresh": "Refresh"
    }
  },
  "ja": {
    "message": {
      "message": "新しいバージョンが利用できます。"
    },
    "label": {
      "refresh": "更新"
    }
  }
}
</i18n>
