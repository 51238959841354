<script>
import i18n from '@/i18n'

export default {
  name: "BaseToastContent",
  i18n,
}
</script>
<style scoped>
.btn {
  border: 1px solid white;
  color: white;  
  opacity: 0.8;
  transition: opacity .15s ease-in-out;
}
.btn:hover {
  color: white;
  opacity: 1;
}
</style>
