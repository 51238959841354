<template>
  <div id="main">
    <div class="container">
      <div id="app-header" class="card bg-light">
        <div class="card-body py-2">
          <span class="card-title">
            <router-link :to="`/${lang}/`">
              {{ appTitle }}
            </router-link>
          </span>
          <span>{{ 'v' + appVersion }}</span>
          <span class="flex-end dropdown">
            <a class="btn language dropdown-toggle" data-toggle="dropdown">
              <font-awesome-icon :icon="['fas', 'language']" />
            </a>
            <div class="dropdown-menu">
               <router-link class="dropdown-item" to="/ja/">Japanese</router-link>
               <router-link class="dropdown-item" to="/en/">English</router-link>
            </div>
          </span>
          <span>
            <a class="repo-link"
              title="SDM repository (Bitbucket)"
              href="https://bitbucket.org/shama265/swiss-draw-manager/"
              target="_blank">
              <font-awesome-icon :icon="['fab', 'bitbucket']" />
            </a>
          </span>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import ToastPwaRefresh from '@/components/ToastPwaRefresh.vue'

export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    isWaitingRefresh() {
      return this.$store.state.isWaitingRefresh
    },
    lang() {
      return this.$route.params.lang
    }
  },
  created() {
  },
  watch: {
    isWaitingRefresh(newValue) {
      if(newValue) {
        this.$toast.info({
          component: ToastPwaRefresh,
        }, {
          timeout: false,
          closeOnClick: false,
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let lang = to.params.lang
    next(vm => vm.changeLocale(lang))
  },
  beforeRouteUpdate(to, from, next) {
    this.changeLocale(to.params.lang)
    next()
  },
  methods: {
    changeLocale(lang) {
      if(lang) {
        this.$root.$i18n.locale = lang
        document.querySelector('html').setAttribute('lang', lang)
      }
    }
  },
}
</script>
<style>
#app-header .card-body {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

#app-header .card-title {
  font-size: 1.5rem;
  margin: 0 1rem 0 0;
}

#app-header .card-title a {
  color: #2c3e50;
}

#app-header .card-title a:hover {
  text-decoration: none;
}

#app-header .flex-end {
  margin-left: auto;
}

#app-header .repo-link {
  font-size: 1.5rem;
  color: #2c3e50;
}

#app-header .repo-link:hover {
  color: #0052cc;
}

.language {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-right: 1rem;
  padding: 0;
  vertical-align: initial;
}

.language:hover {
  color: var(--primary-color);
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav .active:not(.router-link-exact-active) {
  background-color: transparent;
}

#nav .nav-link.router-link-exact-active {
  /* color: hsl(var(--primary-HS), 41%); */
  background-color: var(--primary-color);
  color: white;
}

</style>
<i18n>
{
  "en": {
    "message": {
      "doneRestore": "Restored!"
    }
  },
  "ja": {
    "message": {
      "doneRestore": "復元しました。"
    }
  }
}
</i18n>
